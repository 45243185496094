var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("ul", {
    staticClass: "moon"
  }, [_c("li", {
    staticClass: "crater"
  }), _vm._v(" "), _c("li", {
    staticClass: "crater"
  }), _vm._v(" "), _c("li", {
    staticClass: "crater"
  })]), _vm._v(" "), _c("ul", {
    staticClass: "mountain-range"
  }, [_c("li", {
    staticClass: "mountain"
  }), _vm._v(" "), _c("li", {
    staticClass: "mountain"
  }), _vm._v(" "), _c("li", {
    staticClass: "mountain"
  }), _vm._v(" "), _c("li", {
    staticClass: "mountain"
  }), _vm._v(" "), _c("li", {
    staticClass: "mountain"
  }), _vm._v(" "), _c("li", {
    staticClass: "mountain"
  }), _vm._v(" "), _c("li", {
    staticClass: "mountain"
  }), _vm._v(" "), _c("li", {
    staticClass: "mountain"
  }), _vm._v(" "), _c("li", {
    staticClass: "mountain"
  }), _vm._v(" "), _c("li", {
    staticClass: "mountain"
  }), _vm._v(" "), _c("li", {
    staticClass: "mountain"
  })]), _vm._v(" "), _c("div", {
    staticClass: "mountain-range-mask"
  }), _vm._v(" "), _c("ul", {
    staticClass: "forest"
  }, [_c("li", {
    staticClass: "hill"
  }), _vm._v(" "), _c("li", {
    staticClass: "hill"
  }), _vm._v(" "), _c("li", {
    staticClass: "hill"
  })]), _vm._v(" "), _c("ul", {
    staticClass: "sparkles"
  }, [_c("li", {
    staticClass: "sparkle one"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle one"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle one"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle one"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle two"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle two"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle two"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle two"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle three"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle three"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle three"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle three"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle four"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle four"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle four"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle four"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle five"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle five"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle five"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle five"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle six"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle six"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle six"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle six"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle seven"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle seven"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle seven"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle seven"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle eight"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle eight"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle eight"
  }), _vm._v(" "), _c("li", {
    staticClass: "sparkle eight"
  })]), _vm._v(" "), _c("div", {
    staticClass: "grass"
  }, [_c("div", {
    staticClass: "pokemon"
  }, [_c("div", {
    staticClass: "poke",
    attrs: {
      id: "bulbasaur"
    }
  }, [_c("div", {
    staticClass: "ear"
  }), _vm._v(" "), _c("div", {
    staticClass: "ear"
  }), _vm._v(" "), _c("div", {
    staticClass: "head"
  }), _vm._v(" "), _c("div", {
    staticClass: "leg"
  }), _vm._v(" "), _c("div", {
    staticClass: "bulba-body"
  }), _vm._v(" "), _c("div", {
    staticClass: "bulbs"
  }, [_c("div", {
    staticClass: "bulb"
  })])]), _vm._v(" "), _c("div", {
    staticClass: "poke",
    attrs: {
      id: "pikachu"
    }
  }, [_c("div", {
    staticClass: "ear"
  }), _vm._v(" "), _c("div", {
    staticClass: "ear"
  }), _vm._v(" "), _c("div", {
    staticClass: "hand"
  }), _vm._v(" "), _c("div", {
    staticClass: "pika-body"
  }), _vm._v(" "), _c("div", {
    staticClass: "head"
  }), _vm._v(" "), _c("div", {
    staticClass: "pika-tail"
  })])])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };